import React from "react";
import TableVacancies from '../table/tableVacancies'

const Vacancies = () => {
    return(
        <React.Fragment>
            <TableVacancies/>
        </React.Fragment>
    );
}

export default Vacancies;